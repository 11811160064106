import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { VALIDATION_REGEX } from '../../../../utils/generic/constants';
import {
  checkIngestionActionForMandatoryFields,
  isValidDate,
} from '../../../../utils/generic/helper';

export const validate = (
  values,
  isValidate,
  reDisEmails,
  reDisPhones,
  reClEmails,
  reClPhones,
  ethicemails,
  ethicsPhones,
) => {
  const errors = {};
  const contactInfoInternalComments = values.contactInfoInternalComments
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  const contactPhoneReDisclosure = values?.contactPhoneReDisclosure?.split(', ')[0];
  const contactEmailReDisclosure = values?.contactEmailReDisclosure?.split(', ')[0];
  const contactPhoneReClinicalTrial = values?.contactPhoneReClinicalTrial?.split(', ')[0];
  const contactEmailReClinicalTrial = values?.contactEmailReClinicalTrial?.split(', ')[0];
  const contactDetailsEmailsEthics = values?.contactDetailsEmailsEthics?.split(', ')[0];
  const contactDetailsPhonesEthics = values?.contactDetailsPhonesEthics?.split(',')[0];
  const websiteDisclosureRegulation = values?.websiteDisclosureRegulation?.split(', ')[0];
  const websiteCTRegulation = values?.websiteCTRegulation?.split(', ')[0];
  const websiteEthicsReq = values?.websiteEthicsReq?.split(', ')[0];

  if (
    values.contactInformationDisclosure?.trim() &&
    values.contactInformationDisclosure.trim().length > 1000
  ) {
    errors.contactInformationDisclosure = 'Please enter only 1000 characters';
  }
  if (
    values.contactInformationClinical?.trim() &&
    values.contactInformationClinical.trim().length > 1000
  ) {
    errors.contactInformationClinical = 'Please enter only 1000 characters';
  }

  if (
    values.contactInformationNameEthics?.trim() &&
    values.contactInformationNameEthics.trim().length > 1000
  ) {
    errors.contactInformationNameEthics = 'Please enter only 1000 characters';
  }

  if (isValidate) {
    if (
      contactEmailReDisclosure?.trim() &&
      !VALIDATION_REGEX.EMAIL.test(contactEmailReDisclosure?.trim())
    ) {
      errors.contactEmailReDisclosure = 'Please enter valid email address';
    }
    if (
      contactPhoneReClinicalTrial?.trim() &&
      !VALIDATION_REGEX.PHONE.test(contactPhoneReClinicalTrial?.trim())
    ) {
      errors.contactPhoneReClinicalTrial = 'Please enter valid phone numbers';
    }
    if (contactPhoneReClinicalTrial?.trim() && contactPhoneReClinicalTrial.length > 30) {
      errors.contactPhoneReClinicalTrial = 'Please enter only 30 characters';
    }
    if (
      contactEmailReClinicalTrial?.trim() &&
      !VALIDATION_REGEX.EMAIL.test(contactEmailReClinicalTrial?.trim())
    ) {
      errors.contactEmailReClinicalTrial = 'Please enter valid email address';
    }
    if (
      contactDetailsEmailsEthics?.trim() &&
      !VALIDATION_REGEX.EMAIL.test(contactDetailsEmailsEthics?.trim())
    ) {
      errors.contactDetailsEmailsEthics = 'Please enter valid email address';
    }
    if (
      contactDetailsPhonesEthics?.trim() &&
      !VALIDATION_REGEX.PHONE.test(contactDetailsPhonesEthics?.trim())
    ) {
      errors.contactDetailsPhonesEthics = 'Please enter valid phone numbers';
    }
    if (contactDetailsPhonesEthics?.trim() && contactDetailsPhonesEthics.length > 30) {
      errors.contactDetailsPhonesEthics = 'Please enter only 30 characters';
    }
    if (values.contactInfoInternalComments?.trim() && contactInfoInternalComments.length > 7000) {
      errors.contactInfoInternalComments = 'Please enter only 7000 characters';
    }
    if (
      websiteCTRegulation?.trim() &&
      !VALIDATION_REGEX.WEBSITE.test(websiteCTRegulation?.trim())
    ) {
      errors.websiteCTRegulation = 'Please enter a valid website';
    }
    if (
      websiteDisclosureRegulation?.trim() &&
      !VALIDATION_REGEX.WEBSITE.test(websiteDisclosureRegulation?.trim())
    ) {
      errors.websiteDisclosureRegulation = 'Please enter a valid website';
    }
    if (websiteEthicsReq?.trim() && !VALIDATION_REGEX.WEBSITE.test(websiteEthicsReq?.trim())) {
      errors.websiteEthicsReq = 'Please enter a valid website';
    }
  } else {
    if (
      values.contactPhoneReDisclosure?.trim() &&
      !VALIDATION_REGEX.PHONE.test(values.contactPhoneReDisclosure?.trim())
    ) {
      errors.contactPhoneReDisclosure = 'Please enter valid phone numbers';
    }
    if (values.contactPhoneReDisclosure?.trim() && values.contactPhoneReDisclosure.length > 30) {
      errors.contactPhoneReDisclosure = 'Please enter only 30 characters';
    }
    if (
      values.contactEmailReDisclosure?.trim() &&
      !VALIDATION_REGEX.EMAIL.test(values.contactEmailReDisclosure?.trim())
    ) {
      errors.contactEmailReDisclosure = 'Please enter valid email address';
    }
    if (
      values.contactPhoneReClinicalTrial?.trim() &&
      !VALIDATION_REGEX.PHONE.test(values.contactPhoneReClinicalTrial?.trim())
    ) {
      errors.contactPhoneReClinicalTrial = 'Please enter valid phone numbers';
    }
    if (
      values.contactPhoneReClinicalTrial?.trim() &&
      values.contactPhoneReClinicalTrial.length > 30
    ) {
      errors.contactPhoneReClinicalTrial = 'Please enter only 30 characters';
    }
    if (
      values.contactEmailReClinicalTrial?.trim() &&
      !VALIDATION_REGEX.EMAIL.test(values.contactEmailReClinicalTrial?.trim())
    ) {
      errors.contactEmailReClinicalTrial = 'Please enter valid email address';
    }
    if (
      values.contactDetailsEmailsEthics?.trim() &&
      !VALIDATION_REGEX.EMAIL.test(values.contactDetailsEmailsEthics?.trim())
    ) {
      errors.contactDetailsEmailsEthics = 'Please enter valid email address';
    }
    if (
      contactDetailsPhonesEthics?.trim() &&
      !VALIDATION_REGEX.PHONE.test(contactDetailsPhonesEthics?.trim())
    ) {
      errors.contactDetailsPhonesEthics = 'Please enter valid phone numbers';
    }
    if (contactDetailsPhonesEthics?.trim() && contactDetailsPhonesEthics.length > 30) {
      errors.contactDetailsPhonesEthics = 'Please enter only 30 characters';
    }
    if (values.contactInfoInternalComments?.trim() && contactInfoInternalComments.length > 7000) {
      errors.contactInfoInternalComments = 'Please enter only 7000 characters';
    }
    if (
      websiteCTRegulation?.trim() &&
      !VALIDATION_REGEX.WEBSITE.test(websiteCTRegulation?.trim())
    ) {
      errors.websiteCTRegulation = 'Please enter a valid website';
    }
    if (
      websiteDisclosureRegulation?.trim() &&
      !VALIDATION_REGEX.WEBSITE.test(websiteDisclosureRegulation?.trim())
    ) {
      errors.websiteDisclosureRegulation = 'Please enter a valid website';
    }
    if (websiteEthicsReq?.trim() && !VALIDATION_REGEX.WEBSITE.test(websiteEthicsReq?.trim())) {
      errors.websiteEthicsReq = 'Please enter a valid website';
    }

    if (reDisEmails?.length > 0) {
      for (let i = 0; i < reDisEmails.length; i++) {
        let emailField = reDisEmails[i].name;
        let emailValue = reDisEmails[i].value;
        if (emailValue?.trim() && !VALIDATION_REGEX.EMAIL.test(emailValue)) {
          errors[emailField] = 'Please enter valid email address';
        }
      }
    }

    if (reClEmails?.length > 0) {
      for (let i = 0; i < reClEmails.length; i++) {
        let emailField = reClEmails[i].name;
        let emailValue = reClEmails[i].value;
        if (emailValue?.trim() && !VALIDATION_REGEX.EMAIL.test(emailValue)) {
          errors[emailField] = 'Please enter valid email address';
        }
      }
    }

    if (reClPhones?.length > 0) {
      for (let i = 0; i < reClPhones.length; i++) {
        let phoneField = reClPhones[i].name;
        let phoneValue = reClPhones[i].value;
        if (phoneValue?.trim() && !VALIDATION_REGEX.PHONE.test(phoneValue)) {
          errors[phoneField] = 'Please enter valid phone numbers';
        }
        if (phoneValue?.trim() && phoneValue.length > 30) {
          errors[phoneField] = 'Please enter only 30 characters';
        }
      }
    }
    if (ethicemails?.length > 0) {
      for (let i = 0; i < ethicemails.length; i++) {
        let emailField = ethicemails[i].name;
        let emailValue = ethicemails[i].value;
        if (emailValue?.trim() && !VALIDATION_REGEX.EMAIL.test(emailValue)) {
          errors[emailField] = 'Please enter valid email address';
        }
      }
    }
    if (ethicsPhones?.length > 0) {
      for (let i = 0; i < ethicsPhones.length; i++) {
        let phoneField = ethicsPhones[i].name;
        let phoneValue = ethicsPhones[i].value;
        if (phoneValue?.trim() && !VALIDATION_REGEX.PHONE.test(phoneValue)) {
          errors[phoneField] = 'Please enter valid phone numbers';
        }
        if (phoneValue?.trim() && phoneValue.length > 30) {
          errors[phoneField] = 'Please enter only 30 characters';
        }
      }
    }
    if (websiteCTRegulation?.length > 0) {
      for (let i = 0; i < websiteCTRegulation.length; i++) {
        let websiteField = websiteCTRegulation[i].name;
        let websiteValue = websiteCTRegulation[i].value;
        if (websiteValue?.trim() && !VALIDATION_REGEX.WEBSITEEMAIL.test(websiteValue)) {
          errors[websiteField] = 'Please enter a valid website';
        }
      }
    }
    if (reDisEmails?.length > 0) {
      for (let i = 0; i < reDisEmails.length; i++) {
        let websiteField = reDisEmails[i].name;
        let websiteValue = reDisEmails[i].value;
        if (websiteValue?.trim() && !VALIDATION_REGEX.WEBSITE.test(websiteValue)) {
          errors[websiteField] = 'Please enter a valid website';
        }
      }
    }
    if (reDisEmails?.length > 0) {
      for (let i = 0; i < reDisEmails.length; i++) {
        let websiteField = reDisEmails[i].name;
        let websiteValue = reDisEmails[i].value;
        if (websiteValue?.trim() && !VALIDATION_REGEX.WEBSITE.test(websiteValue)) {
          errors[websiteField] = 'Please enter a valid website';
        }
      }
    }
  }

  return errors;
};

export const validateEmail = value => {
  let errors = '';
  if (value?.trim() && !VALIDATION_REGEX.EMAIL.test(value?.trim())) {
    errors = 'Please enter valid email address';
  }
  return errors;
};

export const validateWebsite = value => {
  let errors = '';
  if (value?.trim() && !VALIDATION_REGEX.WEBSITE.test(value?.trim())) {
    errors = 'Please enter a valid website';
  }
  return errors;
};

export const validatePhone = value => {
  let errors = '';
  if (value?.trim() && !VALIDATION_REGEX.PHONE.test(value?.trim())) {
    errors = 'Please enter valid phone numbers';
  }
  if (value?.trim() && value.length > 30) {
    errors = 'Please enter only 30 characters';
  }
  return errors;
};

export const getMultiInputData = data => {
  if (data) {
    const arr = data?.split(', ');
    if (arr.length > 1) {
      arr.shift();
      return arr;
    }
  }
};

export const getDefaultVal = data => {
  if (data) {
    const arr = data.split(', ');
    if (arr.length) {
      if (arr.length > 1) {
        return arr.shift();
      } else {
        return arr[0];
      }
    }
  }
};

// export const initialValues = {
//   contactEmailReDisclosure: '',
//   contactPhoneReDisclosure: '',
//   contactEmailReClinicalTrial: '',
//   contactPhoneReClinicalTrial: '',
// };

export const layoutSchema = {
  layout: 'row',
  size: 12,
  className: 'reason-for-update',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  type: fieldTypes.select,
  options: [],
  props: {
    height: 'ht-lg',
  },
  content: [
    {
      layout: 'contactInformationDisclosure',
      size: 12,
      className: '',
    },
    {
      layout: 'contactEmailReDisclosure',
      size: 6,
      className: '',
    },
    {
      layout: 'contactPhoneReDisclosure',
      size: 6,
      className: '',
    },
    { layout: 'websiteDisclosureRegulation', size: 6, className: '' },
    {
      layout: 'contactInformationClinical',
      size: 12,
      className: '',
    },
    {
      layout: 'contactEmailReClinicalTrial',
      size: 6,
      className: '',
    },
    {
      layout: 'contactPhoneReClinicalTrial',
      size: 6,
      className: '',
    },
    { layout: 'websiteCTRegulation', size: 6, className: '' },
    {
      layout: 'contactInformationNameEthics',
      size: 12,
      className: '',
    },
    {
      layout: 'contactDetailsEmailsEthics',
      size: 6,
      className: '',
    },
    {
      layout: 'contactDetailsPhonesEthics',
      size: 6,
      className: '',
    },
    { layout: 'websiteEthicsReq', size: 6, className: '' },
    {
      layout: 'contactInfoInternalComments',
      size: 12,
      className: '',
    },
  ],
};

export const formSchema = {
  contactInformationDisclosure: {
    name: 'contactInformationDisclosure',
    type: fieldTypes.text,
    label: 'Contact Information - Name / Organization (RE Disclosure Regulation)',
  },
  contactEmailReDisclosure: {
    name: 'contactEmailReDisclosure',
    type: fieldTypes.text,
    label: 'Contact information - Email (re disclosure regulation)',
    props: {
      showIcons: true,
    },
  },
  contactPhoneReDisclosure: {
    name: 'contactPhoneReDisclosure',
    type: fieldTypes.phoneNumberWithCountryCode,
    label: 'Contact information - Phone (re disclosure regulation)',
    props: {
      rowsMin: 10,
      rowsMax: 2.3,
      cols: 150,
      showIcons: true,
    },
  },
  websiteDisclosureRegulation: {
    name: 'websiteDisclosureRegulation',
    type: fieldTypes.text,
    label: 'CONTACT DETAILS - WEBSITE (re disclosure regulation)',
    props: {
      showIcons: true,
      limit: 4,
    },
  },
  contactInformationClinical: {
    name: 'contactInformationClinical',
    type: fieldTypes.text,
    label: 'Contact Information - Name / Organization (RE Clinical Trial Regulation/Requirement)',
  },
  contactEmailReClinicalTrial: {
    name: 'contactEmailReClinicalTrial',
    type: fieldTypes.text,
    label: 'Contact information - Email (re clinical trial regulation/requirement)',
    props: {
      showIcons: true,
    },
  },
  contactPhoneReClinicalTrial: {
    name: 'contactPhoneReClinicalTrial',
    type: fieldTypes.phoneNumberWithCountryCode,
    label: 'Contact information - Phone (re clinical trial regulation/requirement)',
    props: {
      rowsMin: 10,
      rowsMax: 2.3,
      cols: 150,
      showIcons: true,
    },
  },
  websiteCTRegulation: {
    name: 'websiteCTRegulation',
    type: fieldTypes.text,
    label: 'CONTACT DETAILS - WEBSITE (RE CLINICAL TRIAL REGULATION/REQUIREMENT)',
    props: {
      showIcons: true,
      limit: 4,
    },
  },
  contactInformationNameEthics: {
    name: 'contactInformationNameEthics',
    type: fieldTypes.text,
    label: 'Contact Information - Name / Organization (ethics requirements)',
  },
  contactDetailsEmailsEthics: {
    name: 'contactDetailsEmailsEthics',
    type: fieldTypes.text,
    label: 'Contact details - Email (ethics requirements)',
    props: {
      showIcons: true,
    },
  },
  contactDetailsPhonesEthics: {
    name: 'contactDetailsPhonesEthics',
    type: fieldTypes.phoneNumberWithCountryCode,
    label: 'Contact details - Phone (ethics requirements)',
    props: {
      rowsMin: 10,
      rowsMax: 2.3,
      cols: 150,
      showIcons: true,
    },
  },
  websiteEthicsReq: {
    name: 'websiteEthicsReq',
    type: fieldTypes.text,
    label: 'CONTACT DETAILS - WEBSITE (ETHICS REQUIREMENTS)',
    props: {
      showIcons: true,
      limit: 4,
    },
  },
  contactInfoInternalComments: {
    name: 'contactInfoInternalComments',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 10,
      rowsMax: 5,
      cols: 150,
    },
  },
};

const generateTaxonomyInitialState = data => {
  const initialState = {};
  data.forEach(item => {
    if (item.value === 'location') {
      initialState[item.value] = {
        taxonomyLabel: item.name,
        taxonomyValue: item.value,
        locationData: [],
        selectedRegionId: 0,
        selectedSubRegionId: 0,
        selectedCountryId: 0,
      };
    } else {
      initialState[item.value] = {
        taxonomyLabel: item.name,
        taxonomyValue: item.value,
        tableData: {
          data: [],
          error: null,
          isError: false,
          isFetching: false,
        },
        formValues: null,
        filter: '',
      };
    }
  });
  return initialState;
};
const drugs = 'drugs';
const trials = 'trials';
const sites = 'sites';
const admin = 'admin';
const paging = 'paging';
const sourceDoc = 'sourceDoc';
const countryRegion = 'countryRegion';
const language = 'language';
const registryAbbreviationName = 'registryAbbreviationName';
const registryFullName = 'registryFullName';
const registryGovernance = 'registryGovernance';
const requirement = 'requirement';
const information = 'information';
const studyType = 'studyType';
const studyPhase = 'studyPhase';
const interventionalType = 'interventionalType';
const regVersion = 'regVersion';
const aCTAbbrivatedName = 'aCTAbbrivatedName';
const docType = 'docType';

var loggedInUserGroup = localStorage.getItem('usergroup');
export const taxonomyValues =
  loggedInUserGroup == 'StarUser'
    ? [
        {
          id: '1',
          name: 'Arm Type',
          value: 'armType',
          apiKey: 'armType',
          group: trials,
          primaryField: 'desc',
        },
        {
          id: '10',
          name: 'Assign Patient Segment',
          value: 'diseaseClassification',
          apiKey: 'diseaseClassification',
          group: trials,
          listKey: ['therapeuticArea'],
          primaryField: 'diseaseType.desc',
          editConfirm: true,
        },
        {
          id: '2',
          name: 'Associated CRO',
          value: 'associatedCRO',
          apiKey: 'associatedCRO',
          group: trials,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '1',
          name: 'Central IRB',
          value: 'centralIRB',
          apiKey: 'organizationIRB',
          group: sites,
          primaryField: 'organizationCentralIRB',
        },
        {
          id: '4',
          name: 'Collaborator',
          value: 'collaborator',
          apiKey: 'collaborator',
          group: trials,
          primaryField: 'type',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '5',
          name: 'Collaborator Type',
          value: 'collaboratorType',
          apiKey: 'collaboratorType',
          group: trials,
          primaryField: 'desc',
        },
        {
          id: '6',
          name: 'Company',
          value: 'company',
          apiKey: 'company',
          group: paging,
          listKey: ['researchFocus'],
          primaryField: 'name',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '9',
          name: 'Degree',
          value: 'degree',
          apiKey: 'degree',
          group: sites,
          primaryField: 'degree',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '12',
          name: 'Delivery Medium',
          value: 'deliveryMedium',
          apiKey: 'deliveryMedium',
          group: drugs,
          primaryField: 'description',
        },
        {
          id: '13',
          name: 'Delivery Route',
          value: 'deliveryRoute',
          apiKey: 'deliveryRoute',
          group: drugs,
          primaryField: 'description',
        },
        {
          id: '14',
          name: 'Delivery Technology',
          value: 'deliveryTechnology',
          apiKey: 'deliveryTechnology',
          group: drugs,
          primaryField: 'description',
        },
        {
          id: '15',
          name: 'Design Keyword',
          value: 'designKeywords',
          apiKey: 'designKeywords',
          group: trials,
          primaryField: 'designkeyword',
        },
        {
          id: '16',
          name: 'Development Stages',
          value: 'drugDevelopmentStatus',
          apiKey: 'drugDevelopmentStatus',
          group: drugs,
          primaryField: 'description',
        },
        {
          id: '11',
          name: 'Drug Country',
          value: 'drugCountry',
          apiKey: 'country',
          group: drugs,
          listKey: ['countryGroup'],
          primaryField: 'countryName',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '8',
          name: 'Drug Country Group',
          value: 'countryGroup',
          apiKey: 'countryGroup',
          group: drugs,
          primaryField: 'name',
        },
        {
          id: '22',
          name: 'Drug Disease',
          value: 'indication',
          apiKey: 'indication',
          group: drugs,
          listKey: ['indicationGroup'],
          primaryField: 'indication',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '23',
          name: 'Drug Disease Group',
          value: 'indicationGroup',
          apiKey: 'indicationGroup',
          group: drugs,
          primaryField: 'name',
        },
        {
          id: '18',
          name: 'Drug Notes Type',
          value: 'notesType',
          apiKey: 'notesType',
          group: drugs,
          primaryField: 'description',
        },
        {
          id: '50',
          name: 'DRUG Therapeutic Class',
          value: 'therapeuticClass',
          apiKey: 'therapeuticClass',
          group: drugs,
          primaryField: 'description',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '27',
          name: 'Drug Type',
          value: 'drugType',
          apiKey: 'DrugTypeClassification',
          group: drugs,
          primaryField: 'description',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '20',
          name: 'Endpoint Synonym',
          value: 'endpointSynonyms',
          apiKey: 'EndpointSynonym',
          group: admin,
          listKey: ['EndpointArea'],
          nonAncillaryList: {
            apiKey: 'EndpointArea',
            group: admin,
          },
          primaryField: 'endpointName.preferredEndpointName',
          idAccessor: 'endpointName',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '19',
          name: 'Event Types',
          value: 'eventTypes',
          apiKey: 'eventTypes',
          group: drugs,
          primaryField: 'description',
        },
        {
          id: '21',
          name: 'Event Type Group',
          value: 'eventGroup',
          apiKey: 'eventGroup',
          group: drugs,
          primaryField: 'eventGroup',
        },
        {
          id: '58',
          name: 'Immunoconjugate payload',
          value: 'payload',
          apiKey: 'ImmunoconjugatePayload',
          group: drugs,
          primaryField: 'payload',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '24',
          name: 'Journal Name',
          value: 'journalName',
          apiKey: 'journalName',
          group: trials,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '25',
          name: 'License Id Type',
          value: 'licenseIdType',
          apiKey: 'licenseIdType',
          group: sites,
          primaryField: 'description',
        },
        {
          id: '27',
          name: 'Mechanisms',
          value: 'mechanismOfAction',
          apiKey: 'mechanismOfAction',
          group: drugs,
          primaryField: 'description',
          pagination: {
            pageIndex: 1,
            pageSize: 10000,
          },
        },
        {
          id: '28',
          name: 'Meeting/Conference Name',
          value: 'conferenceName',
          apiKey: 'conferenceName',
          group: trials,
          primaryField: 'desc',
        },
        {
          id: '29',
          name: 'Organization Type',
          value: 'organizationType',
          apiKey: 'organizationType',
          group: sites,
          primaryField: 'type',
        },
        {
          id: '30',
          name: 'Origin of Material',
          value: 'originOfMaterial',
          apiKey: 'originOfMaterial',
          group: drugs,
          primaryField: 'description',
        },
        {
          id: '31',
          name: 'Patient Segments',
          value: 'patientSegment',
          apiKey: 'patientSegment',
          group: trials,
          listKey: ['diseaseType'],
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '32',
          name: 'Patient Segment Synonym(s)',
          value: 'patientSegmentSynonym',
          apiKey: 'patientSegmentSynonym',
          group: trials,
          primaryField: 'patientSegment.desc',
          hideAddForm: true,
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '52',
          name: 'Person Role',
          value: 'role',
          apiKey: 'role',
          group: trials,
          primaryField: 'roleDescription',
        },
        {
          id: '36',
          name: 'Person Type',
          value: 'personType',
          apiKey: 'personType',
          group: sites,
          primaryField: 'personType',
        },
        {
          id: '33',
          name: 'Pharmacokinetics Parameter',
          value: 'pharmacokineticsParameter',
          apiKey: 'pharmacokineticsParameter',
          group: drugs,
          primaryField: 'parameter',
        },
        {
          id: '34',
          name: 'Pharmacokinetics Unit',
          value: 'pharmacokineticsUnit',
          apiKey: 'pharmacokineticsUnit',
          group: drugs,
          primaryField: 'unit',
        },
        {
          id: '35',
          name: 'Physician ID Type',
          value: 'physicianIdType',
          apiKey: 'physicianIdType',
          group: sites,
          primaryField: 'description',
        },
        {
          id: '38',
          name: 'Regulatory Action Flag',
          value: 'regulatoryActionFlag',
          apiKey: 'regulatoryActionFlag',
          group: sites,
          primaryField: 'flag',
        },
        {
          id: '37',
          name: 'Regulatory Action Type',
          value: 'regulatoryActionType',
          apiKey: 'regulatoryActionType',
          listKey: ['regulatoryActionFlag'],
          group: sites,
          primaryField: 'description',
        },
        {
          id: '39',
          name: 'Research Focus',
          value: 'researchFocus',
          apiKey: 'researchFocus',
          group: sites,
          primaryField: 'description',
        },
        {
          id: '40',
          name: 'Result Type',
          value: 'resultType',
          apiKey: 'resultType',
          group: trials,
          primaryField: 'desc',
        },
        {
          id: '3',
          name: 'Sitetrove City',
          value: 'city',
          apiKey: 'city',
          group: sites,
          primaryField: 'cityName',
          editConfirm: true,
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '7',
          name: 'Sitetrove Country',
          value: 'country',
          apiKey: 'country',
          group: sites,
          primaryField: 'countryName',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '45',
          name: 'Sitetrove State',
          value: 'state',
          apiKey: 'state',
          group: sites,
          primaryField: 'stateName',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
          editConfirm: true,
        },
        {
          id: '41',
          name: 'Source Type',
          value: 'sourceType',
          apiKey: 'sourceType',
          group: trials,
          primaryField: 'desc',
        },
        {
          id: '42',
          name: 'Specialty',
          value: 'specialties',
          apiKey: 'specialties',
          group: sites,
          primaryField: 'specialties',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '43',
          name: 'SPONSOR/COLLABORATOR',
          value: 'sponsor',
          apiKey: 'Sponsor',
          group: trials,
          listKey: ['sponsorType'],
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '44',
          name: 'SPONSOR/COLLABORATOR TYPE',
          value: 'sponsorType',
          apiKey: 'sponsorType',
          group: trials,
          primaryField: 'desc',
        },
        {
          id: '46',
          name: 'Stock Exchange',
          value: 'stockExchange',
          apiKey: 'stockExchange',
          group: sites,
          primaryField: 'exchange',
        },
        {
          id: '47',
          name: 'Target',
          value: 'target',
          apiKey: 'target',
          group: drugs,
          listKey: ['targetFamily'],
          primaryField: 'target',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '48',
          name: 'Target Family',
          value: 'targetFamily',
          apiKey: 'targetFamily',
          group: drugs,
          primaryField: 'targetFamily',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '57',
          name: 'Trial Note Type',
          value: 'noteType',
          apiKey: 'noteType',
          group: trials,
          primaryField: 'desc',
        },
        {
          id: '51',
          name: 'Trial Phases',
          value: 'phase',
          apiKey: 'phase',
          group: trials,
          primaryField: 'desc',
        },
        {
          id: '53',
          name: 'Trial Source',
          value: 'source',
          apiKey: 'source',
          group: trials,
          primaryField: 'description',
        },
        {
          id: '54',
          name: 'Trial Status',
          value: 'globalStatus',
          apiKey: 'globalStatus',
          group: trials,
          primaryField: 'desc',
        },
        {
          id: '55',
          name: 'Trial Tags',
          value: 'tag',
          apiKey: 'tag',
          group: trials,
          primaryField: 'desc',
        },
        {
          id: '56',
          name: 'Trial Termination Reason',
          value: 'terminationReason',
          apiKey: 'terminationReason',
          group: trials,
          primaryField: 'desc',
        },
        {
          id: '26',
          name: 'TrialTrove Location',
          value: 'location',
          apiKey: 'location',
          group: sites,
          primaryField: 'description',
        },
        {
          id: '17',
          name: 'Trialtrove Disease Type',
          value: 'diseaseType',
          apiKey: 'diseaseType',
          group: trials,
          listKey: ['therapeuticArea'],
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
          editConfirm: true,
        },
        {
          id: '49',
          name: 'TrialTrove Therapeutic Area',
          value: 'therapeuticArea',
          apiKey: 'therapeuticArea',
          group: trials,
          primaryField: 'desc',
        },
      ]
    : [
        {
          id: '101',
          name: 'Source Name',
          value: 'sourceName',
          apiKey: 'sourceName',
          group: sourceDoc,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '102',
          name: 'Country/Region',
          value: 'countryName',
          apiKey: 'Countries',
          group: countryRegion,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '103',
          name: 'Languages',
          value: 'language',
          apiKey: 'Language',
          group: language,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '104',
          name: 'Registries Abbreviated Name',
          value: 'registryAbbreviation',
          apiKey: 'RegistryAbbreviatedName',
          group: registryAbbreviationName,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '105',
          name: 'Registries Full Name',
          value: 'registryName',
          apiKey: 'RegistryName',
          group: registryFullName,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '106',
          name: 'Registry Governance',
          value: 'registryGovernance',
          apiKey: 'RegistryGovernance',
          group: registryGovernance,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '107',
          name: 'Responsible for data entry',
          value: 'information',
          apiKey: 'ResponsibleForDataEntry',
          group: information,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '108',
          name: 'Requirement/Regulation Abbreviated Name',
          value: 'requirementAbbreviatedName',
          apiKey: 'AbbreviatedName',
          group: requirement,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '126',
          name: 'Clinical Trial Requirement Abbreviated Name',
          value: 'abbreviatedName',
          apiKey: 'ClinicalTrialAbbreviatedName',
          group: requirement,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '109',
          name: 'Study Type',
          value: 'studyType',
          apiKey: 'StudyType',
          group: studyType,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '110',
          name: 'Study Phase',
          value: 'studyPhase',
          apiKey: 'StudyPhase',
          group: studyPhase,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '111',
          name: 'Intervention Type For Registries',
          value: 'interventionalType',
          apiKey: 'InterventionalType',
          group: interventionalType,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '112',
          name: 'Document type',
          value: 'docType',
          apiKey: 'DocumentType',
          group: docType,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '113',
          name: 'Regulatory version',
          value: 'regVersion',
          apiKey: 'RegulatoryVersion',
          group: regVersion,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '114',
          name: 'ACT Abbreviated Name',
          value: 'aCTAbbrivatedName',
          apiKey: 'ACTAbbreviatedName',
          group: aCTAbbrivatedName,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '115',
          name: 'CTA Document Type',
          value: 'ctaDocumentType',
          apiKey: 'CTADocumentType',
          group: requirement,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '116',
          name: 'Submission Type',
          value: 'submissionType',
          apiKey: 'SubmissionType',
          group: requirement,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '117',
          name: 'Document tags',
          value: 'docTags',
          apiKey: 'DocumentTag',
          group: sourceDoc,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '118',
          name: 'Redaction Registration Information',
          value: 'redactionRegistrationInformation',
          apiKey: 'RedactionRegistrationInformation',
          group: requirement,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '119',
          name: 'Redaction Results Type',
          value: 'redactionResultType',
          apiKey: 'RedactionResultType',
          group: requirement,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '120',
          name: 'Redaction Document Type',
          value: 'redactionDocumentType',
          apiKey: 'RedactionDocumentType',
          group: requirement,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '121',
          name: 'Deferral Document Type',
          value: 'deferralDocumentType',
          apiKey: 'DeferralDocumentType',
          group: requirement,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '122',
          name: 'HA Funded Registered Documents',
          value: 'hafundedregistereddocuments',
          apiKey: 'HAFundedRegisteredDocuments',
          group: requirement,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '123',
          name: 'CTA Outcomes',
          value: 'ctaOutcome',
          apiKey: 'CTAOutcomes',
          group: requirement,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '124',
          name: 'Exemptions to CTA',
          value: 'exemptionsCta',
          apiKey: 'ExemptionsToCTA',
          group: requirement,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
        {
          id: '125',
          name: 'Exemptions to Ethics Committee',
          value: 'exemptionstoethicsreview',
          apiKey: 'ExemptionsToEthicsReview',
          group: requirement,
          primaryField: 'desc',
          pagination: {
            pageIndex: 1,
            pageSize: 50,
          },
        },
      ];

//sort the option alphbatically

// taxonomyValues.sort((a, b) => {
//   var keyA = a.name,
//     keyB = b.name; // Compare the 2 column values
//   if (keyA < keyB) {
//     return -1;
//   }
//   if (keyA > keyB) {
//     return 1;
//   }
//   return 0;
// });
export const taxonomyInitialState = generateTaxonomyInitialState(taxonomyValues);

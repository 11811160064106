import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { TEXT_LIMIT, VALIDATION_REGEX } from '../../../../utils/generic/constants';
import {
  checkIngestionActionForMandatoryFields,
  isValidDate,
} from '../../../../utils/generic/helper';

export const validate = (values, nextReviewDate) => {
  const errors = {};
  const executiveSummary = values.executiveSummary.replace(/(\r\n|\n|\r)/gm, '').trim();
  if (!values.reasonForUpdate) {
    errors.reasonForUpdate = 'Please enter reason for update';
  }
  if (!values.updateDesc.trim()) {
    errors.updateDesc = 'Please enter the description';
  }
  if (!values.executiveSummary.trim()) {
    errors.executiveSummary = 'Please enter the summary';
  }
  if (values?.updateDesc?.length > 400) {
    errors.updateDesc = 'Please enter only 400 characters';
  }
  if (!values.repealedStatus) {
    errors.repealedStatus = 'Please select the Repealed Status - Clinical Trial Disclosure';
  }
  if (!values.repealedStatusCTRegulation) {
    errors.repealedStatusCTRegulation =
      'Please select the Repealed Status - Clinical Trial Regulation';
  }
  // if (values.executiveSummary.trim() && executiveSummary.length > TEXT_LIMIT[7000]) {
  //   errors.executiveSummary = 'Please enter only 7000 characters';
  // }
  if (nextReviewDate && !isValidDate(nextReviewDate)) {
    errors.nextFullReviewDate = 'Please enter a valid date (dd-MMM-yyyy)';
  }
  return errors;
};

export const layoutSchema = {
  layout: 'row',
  className: 'person-basic-info-container mb-20',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: 'person-fullname-row mt-20',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      headerProps: {
        fontStyle: 'bold',
        size: '16',
      },
      content: [
        {
          layout: 'reasonForUpdate',
          size: 4,
          className: '',
        },
        {
          layout: 'updateDesc',
          size: 6,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      className: 'description',
      size: 12,
      // spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'repealedStatus',
          size: 5,
          className: '',
        },
        {
          layout: 'repealedStatusCTRegulation',
          size: 5,
          className: '',
        },
      ],
    },
    { layout: 'moduleSelected', size: 10, className: 'timeline' },
    {
      layout: 'row',
      className: 'executive-Summary',
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'executiveSummary',
          size: 12,
        },
      ],
    },
  ],
};

export const formSchema = {
  reasonForUpdate: {
    name: 'reasonForUpdate',
    type: fieldTypes.select,
    label: 'REASON FOR UPDATE',
    props: {
      height: 'ht-lg',
      isMandatory: true,
      isSearchable: true,
      isClearable: true,
    },
  },
  updateDesc: {
    name: 'updateDesc',
    type: fieldTypes.textArea,
    label: 'REASON FOR UPDATE - DESCRIPTION',
    props: {
      rowsMin: 10,
      rowsMax: 2.3,
      cols: 150,
      isMandatory: true,
    },
  },
  repealedStatus: {
    name: 'repealedStatus',
    type: fieldTypes.custom_radio,
    label: 'Repealed Status - Clinical Trial Disclosure',
    options: [
      { id: 1, value: 'ACTIVE', display: 'ACTIVE' },
      { id: 2, value: 'REPEALED', display: 'REPEALED' },
    ],
    props: {
      isMandatory: true,
    },
  },
  repealedStatusCTRegulation: {
    name: 'repealedStatusCTRegulation',
    type: fieldTypes.custom_radio,
    label: 'Repealed Status - Clinical Trial Regulation',
    options: [
      { id: 1, value: 'ACTIVE', display: 'ACTIVE' },
      { id: 2, value: 'REPEALED', display: 'REPEALED' },
    ],
    props: {
      isMandatory: true,
    },
  },
  moduleSelected: {
    name: 'moduleSelected',
    label: 'Module Selection',
    type: fieldTypes.radio,
    options: [
      {
        id: 1,
        value: 'Clinical Trial Disclosure',
        label: 'Clinical Trial Disclosure',
      },
      {
        id: 2,
        value: 'Clinical Trial Regulation',
        label: 'Clinical Trial Regulation',
      },
    ],
    props: {
      type: 'group',
      className: 'timelineRadioClass',
    },
  },
  executiveSummary: {
    name: 'executiveSummary',
    type: fieldTypes.mdEditor,
    label: 'EXECUTIVE SUMMARY',
    props: {
      rowsMin: 10,
      rowsMax: 5,
      cols: 150,
      isMandatory: true,
    },
  },
};

export const repealedStatusMap = { 1: 'Active', 2: 'Repealed' };

export const moduleSelectionMap = {
  'Clinical Trial Disclosure': 1,
  'Clinical Trial Regulation': 2,
};
